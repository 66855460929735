import * as React from 'react';
// import { graphql } from 'gatsby';
// import Img, { FluidObject } from 'gatsby-image';

// import { ReviewsQuery } from '../../graphql-types';
import { Layout } from '../components/Layout';
import { Container } from '../components/Container';
import { Reviews } from '../components/Reviews';
import { Section } from '../components/Section';
import SEO from '../components/seo';

// interface ReviewsProps {
//   data: ReviewsQuery;
// }

const ReviewsPage = () => (
  <Layout>
    <SEO title="Reviews" />

    <Section>
      <Container>
        <h1>Reviews</h1>

        <Reviews />
      </Container>
    </Section>
  </Layout>
);

// export const query = graphql`
//   query Reviews {
//     dave: file(relativePath: { eq: "reviews.png" }) {
//       childImageSharp {
//         fluid(maxWidth: 1076, toFormat: JPG) {
//           ...GatsbyImageSharpFluid_withWebp
//         }
//       }
//     }
//   }
// `;

export default ReviewsPage;
