import * as React from 'react';
import Img, { FixedObject } from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';

import { ReviewsQuery } from '../../../graphql-types';
// import reviews from '../../../content/reviews.json';
import styles from './reviews.module.css';

interface ReviewsProps {
  children?: React.ReactNode;
  ids?: string[];
  limit?: number;
}

export const Reviews = ({ children, ids, limit }: ReviewsProps) => {
  // const data = useStaticQuery<ReviewImagesQuery>(graphql`
  //   query ReviewImages {
  //     allFile(filter: { dir: { regex: "/reviewers/" } }) {
  //       edges {
  //         node {
  //           name
  //           childImageSharp {
  //             fixed(height: 144, width: 144, toFormat: JPG) {
  //               ...GatsbyImageSharpFixed_withWebp
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // `);

  const data = useStaticQuery<ReviewsQuery>(graphql`
    query Reviews {
      allContentfulReview {
        edges {
          node {
            id
            name
            quote {
              quote
            }
            highlight
            image {
              localFile {
                childImageSharp {
                  fixed(
                    cropFocus: ATTENTION
                    height: 144
                    toFormat: JPG
                    width: 144
                  ) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const reviews =
    ids && ids.length > 0
      ? ids.map(
          (id) =>
            data.allContentfulReview.edges.find((r) => r.node.id === id)?.node
        )
      : data.allContentfulReview.edges.map((e) => e.node);

  const sliceEnd = limit ? Math.min(limit, reviews.length) : reviews.length;

  if (reviews && reviews.length) {
    return (
      <section className={styles.reviews} id="reviews">
        {children}

        <div>
          {reviews.slice(0, sliceEnd).map((r) => {
            if (r) {
              const { id, name, highlight } = r;
              const quote = r.quote?.quote as string;

              // const imageData = images.find(n => n.node.name === id);

              // const image =
              //   (imageData?.node.childImageSharp?.fixed as FixedObject) ??
              //   undefined;

              const image = r.image?.localFile?.childImageSharp
                ?.fixed as FixedObject;

              const text = highlight
                ? quote
                    .split(new RegExp(`(${highlight})`, 'gi'))
                    .map((part, i) =>
                      part.toLowerCase() === highlight.toLowerCase() ? (
                        <mark key={i}>{part}</mark>
                      ) : (
                        <React.Fragment key={i}>{part}</React.Fragment>
                      )
                    )
                : quote;

              return (
                <div key={id} className={styles.review} data-review={id}>
                  <div className={styles.image}>
                    {image ? (
                      <Img fixed={image} loading="lazy" alt={name as string} />
                    ) : null}
                  </div>
                  <blockquote className={styles.quote}>
                    <p>{text}</p>
                    <footer className={styles.quoteFooter} itemProp="author">
                      {name}
                    </footer>
                  </blockquote>
                </div>
              );
            }

            return null;
          })}
        </div>
      </section>
    );
  }

  return null;
};
